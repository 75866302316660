

@media (min-width: 109.375rem) and (max-width: 120rem)  {
    #paper {
        position: absolute;
        right: 22rem;
        bottom: 17rem;
    }
    #catImage  {
        position: absolute;
        right: 22rem;
        bottom: 39.7rem;
    }
 }

 @media (min-width: 96.875rem) and (max-width: 109.313rem)  {
    #paper {
        position: absolute;
        right: 11rem;
        bottom: 17rem;
    }
    #catImage  {
        position: absolute;
        right: 11rem;
        bottom: 39.7rem;
    }
 }


 @media (max-height: 62rem){
    #paper {
        position: absolute;
        bottom: 11rem;
    }
    #catImage  {
        position: absolute;
        bottom: 33.7rem;
    }
}

@media (min-width: 121rem) {
    #paper {
        position: absolute;
        right: 16rem;
        bottom: 18rem;
        padding-top: 6rem;
        padding-bottom: 6rem;
    }
    #catImage  {
        position: absolute;
        right: 16rem;
        bottom: 44.9rem;
    }
 }

 @media (max-height: 50rem){
    #paper {
        position: absolute;
        bottom: 6rem;
    }
    #catImage  {
        position: absolute;
        bottom: 28.9rem;
    }
}