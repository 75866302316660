body {
  font-family: 'inter', sans-serif;
  background-color: #222222;
}

.App {
  height: 100vh;
}

a {
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

h3 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 40px;
}
.content {
  padding: 7rem 0;
}

h2 {
  font-size: 20px;
}

.half,
.half .container > .row {
  height: 100%;
}

@media (max-width: 991.98px) {
  .half .bg {
    height: 500px;
  }
}

@media (max-width: 1199.98px) {
  .half .contents,
  .half .bg {
    width: 100%;
  }
}

.half .contents .form-group,
.half .bg .form-group {
  overflow: hidden;
  margin-bottom: 16;
  border: 1px solid #efefef;
  padding: 15px 15px;

  position: relative;
}
.half .contents .form-group label,
.half .bg .form-group label {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
.half .contents .form-group input:focus + label,
.half .bg .form-group input:focus + label {
  visibility: hidden;
}
.half .contents .form-group.first,
.half .bg .form-group.first {
  border-radius: 40px;
  height: 56px;
}
.half .contents .form-group.last,
.half .bg .form-group.last {
  border-bottom: 1px solid #efefef;
  border-radius: 40px;
  height: 56px;
}
.half .contents .form-group label,
.half .bg .form-group label {
  font-size: 16px;
  display: block;
  margin-bottom: 0;
  color: #b3b3b3;
  visibility: hidden;
}
.half .contents .form-group.field--not-empty label,
.half .bg .form-group.field--not-empty label {
  margin-top: -20px;
}
.half .contents .form-control,
.half .bg .form-control {
  border: none;
  padding: 0;
  font-size: 16px;
  border-radius: 0;
  background-color: #f9f9f9;
}
.half .contents .form-control:active,
.half .contents .form-control:focus,
.half .bg .form-control:active,
.half .bg .form-control:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.half .bg {
  background-color: #222222;
  position: relative;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.half a {
  color: #888;
  text-decoration: underline;
}

.half .btn {
  height: 56px;
  max-width: 192px;
  border-radius: 28px;
  margin-top: 40px;
}

.half .forgot-pass {
  position: relative;
  font-size: 15px;
  font-weight: bold;
  color: #89b620;
}

.btn-primary {
  color: #fff;
  background-color: #89b620;
  border-color: #89b620;
}

.btn-primary:hover {
  background-color: #9acc24;
  border-color: #9acc24;
}

.control {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 14px;
}
.control .caption {
  position: relative;
  top: 0.2rem;
  color: #888;
}

.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.img-aud {
  vertical-align: bottom;
  background-size: cover;
  max-width: 480px;
  background-color: #222222;
}

.sub-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  opacity: 60%;
  margin-bottom: 42px;
}

.form-control:focus {
  background-color: #f9f9f9;
  border-color: #89b620;
  background-color: none;
}

.form-control {
  height: auto;
}

.contents {
  background-color: #f9f9f9;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

.logo-aud {
  margin-top: 40px;
  margin-left: 40px;
}

.side-image {
  width: 170%;
  min-width: 320px;
}

.loader {
  color: #7d7c7c;
  font-size: 60px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 0px auto;
  margin-bottom: 30px;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}

.error {
  color: #d8000c;
}

@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}

.row > [class*='col-'] {
  display: flex;
  flex-direction: column;
}

.logo-container {
  padding-bottom: 240px;
}

.login-intro h3,
.login-intro .sub-text {
  white-space: nowrap;
}

a.reset-btn {
  color: #89b620;
  font-size: 15px;
  margin-top: 5px;
  padding: 5px;
}

@media only screen and (max-width: 1199.98px) {
  .contents {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}
